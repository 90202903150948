import { types } from "mobx-state-tree";

import Auth from "./Auth";
import LinksMng from "./LinksMng";

const Store = types.model("Store", {
  linksMng: types.optional(LinksMng, {}),
  auth: Auth,
});

export default Store;
