import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const configProd = {
  apiKey: "AIzaSyDO5nofxAHzRdavf3L4KXVbe3I1Co8cznM",
  authDomain: "super-driver-51527.firebaseapp.com",
  databaseURL: "https://super-driver-51527.firebaseio.com",
  projectId: "super-driver-51527",
  storageBucket: "super-driver-51527.appspot.com",
  messagingSenderId: "631512537963",
};

const configDev = {
  apiKey: "AIzaSyAxTtdOPF5X4LGhREqN2AD_hFxxaaCoOis",
  authDomain: "super-driver-1439d.firebaseapp.com",
  databaseURL: "https://super-driver-1439d.firebaseio.com",
  projectId: "super-driver-1439d",
  storageBucket: "super-driver-1439d.appspot.com",
  messagingSenderId: "854832051379",
};
const config = process.env.NODE_ENV === "production" ? configProd : configDev;
firebase.initializeApp(config);

export const auth = firebase.auth();
export const database = firebase.database();
