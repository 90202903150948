import { types, getEnv, flow } from "mobx-state-tree";

import Profile from "./Profile";

export const STATUS = {
  INIT: "Checking session",
  SIGN_IN: "Signing in",
  READY: "Session ready",
};

export default types
  .model("Auth", {
    status: types.enumeration("Status", Object.values(STATUS)),
    error: types.maybeNull(types.string),
    profile: types.maybeNull(Profile),
  })
  .volatile((self) => ({
    auth: null,
  }))
  .actions((self) => ({
    afterCreate() {
      const { auth } = getEnv(self).firebase;
      auth.onAuthStateChanged(self.onAuthStateChanged);
      self.auth = auth;
    },
    changeStatus(status) {
      self.status = status;
    },
    onAuthStateChanged(user) {
      self.status = STATUS.READY;
      if (!user) {
        self.profile = null;
        return;
      }
      const { displayName, email, photoURL, phoneNumber, uid, metadata } = user;

      self.profile = {
        name: displayName,
        email,
        photoURL,
        uid,
        phoneNumber,
        joinDate: new Date(metadata.creationTime),
      };
    },
    signIn: flow(function* signIn(email, passwd) {
      self.status = STATUS.SIGN_IN;
      try {
        yield self.auth.signInWithEmailAndPassword(email, passwd);
        self.error = "";
      } catch (error) {
        self.status = STATUS.READY;
        self.error = error.message;
      }
    }),
    signOut() {
      self.auth
        .signOut()
        .then(function () {
          // Sign-out successful.
        })
        .catch(function (error) {
          alert("There are some error when signing out");
        });
    },
  }));
