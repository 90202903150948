import { inject, observer } from "mobx-react";
import React from "react";
import { Redirect } from "react-router-dom";

import { STATUS } from "../stores/Auth";

export const withAuth = (Component) => {
  return inject("store")(
    observer(function (props) {
      const { status, profile } = props.store.auth;
      if (status === STATUS.READY && profile) {
        return <Component {...props} />;
      }
      const redirectTo = props.location.pathname;
      return <Redirect to={`/login?to=${encodeURIComponent(redirectTo)}`} />;
    }),
  );
};
