import { observer, inject } from "mobx-react";
import React from "react";

import classes from "./LoadingIcon.scss";

@inject("ui")
@observer
class LoadingIcon extends React.Component {
  render() {
    const { text = "Processing" } = this.props;
    return (
      <div className={classes.loading}>
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
        <p>{text}</p>
      </div>
    );
  }
}

export default LoadingIcon;
