import skins from "assets/skins.json";
import cl from "classnames";
import { inject, observer } from "mobx-react";
import React from "react";

import SkinCard from "./SkinCard";

@inject("ui")
@observer
class RightSidebar extends React.Component {
  changeLayout = (e) => {
    const { changeLayout } = this.props.ui;
    changeLayout(e.target.value);
  };
  render() {
    const { rightSidebarOpen, layout } = this.props.ui;
    return (
      <React.Fragment>
        <aside
          className={cl({
            "control-sidebar control-sidebar-dark": true,
            "control-sidebar-open": rightSidebarOpen,
          })}
        >
          <div className="tab-content">
            <div id="control-sidebar-theme-demo-options-tab" className="tab-pane active">
              <div>
                <h4 className="control-sidebar-heading">Layout Options</h4>
                <div className="form-group">
                  <label className="control-sidebar-subheading">
                    <input
                      type="radio"
                      name="layout"
                      className="pull-right"
                      value=""
                      checked={layout === ""}
                      onChange={this.changeLayout}
                    />{" "}
                    Normal layout
                  </label>
                  <p>Normal layout.</p>
                </div>
                <div className="form-group">
                  <label className="control-sidebar-subheading">
                    <input
                      type="radio"
                      data-layout="fixed"
                      className="pull-right"
                      value="fixed"
                      checked={layout === "fixed"}
                      onChange={this.changeLayout}
                    />{" "}
                    Fixed layout
                  </label>
                  <p>Activate the fixed layout. Titlebar and Sidebar will fixed to top</p>
                </div>
                <div className="form-group">
                  <label className="control-sidebar-subheading">
                    <input
                      type="radio"
                      data-layout="layout-boxed"
                      className="pull-right"
                      value="layout-boxed"
                      checked={layout === "layout-boxed"}
                      onChange={this.changeLayout}
                    />{" "}
                    Boxed Layout
                  </label>
                  <p>Activate the boxed layout</p>
                </div>
                <h4 className="control-sidebar-heading">Skins</h4>
                <ul className="list-unstyled clearfix">
                  {skins.map((skin, key) => (
                    <SkinCard key={key} skin={skin} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <div className="control-sidebar-bg" />
      </React.Fragment>
    );
  }
}
export default RightSidebar;
