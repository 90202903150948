import React from "react";
import { Link } from "react-router-dom";

export default function ContentHeader({ activeRoute }) {
  return (
    <section className="content-header">
      <h1>{activeRoute.navbarName}</h1>
      <ol className="breadcrumb">
        <li>
          <Link to="/">
            <i className="fa fa-dashboard" /> Home
          </Link>
        </li>
        <li className="active">{activeRoute.navbarName}</li>
      </ol>
    </section>
  );
}
