import "assets/css/style.scss";

import makeInspectable from "mobx-devtools-mst";
import { Provider } from "mobx-react";
import { onSnapshot } from "mobx-state-tree";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Dashboard from "./layouts/Dashboard";
import * as firebase from "./services/firebase";
import { STATUS } from "./stores/Auth";
import Store from "./stores/Store";
import UI from "./stores/UI";
import { withAuth } from "./utils/auth";
import Login from "./views/Login";

// Init UI state tree
const savedUIState = localStorage.getItem("superdrive_ui");
const initialUI = savedUIState ? JSON.parse(savedUIState) : { layout: "", rightSidebarOpen: false };
const ui = UI.create({
  ...initialUI,
  rightSidebarOpen: false,
});
onSnapshot(ui, (snapshot) => {
  localStorage.setItem("superdrive_ui", JSON.stringify(snapshot));
});

// Init data state tree
const store = Store.create(
  {
    auth: {
      status: STATUS.INIT,
    },
  },
  { firebase },
);

if (process.env.NODE_ENV !== "production") {
  makeInspectable(store);
  makeInspectable(ui);
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store} ui={ui}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={withAuth(Dashboard)} />
      </Switch>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root"),
);
