import cl from "classnames";
import dateformat from "dateformat";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import React from "react";

@inject("store")
@observer
class HeaderProfile extends React.Component {
  componentDidMount() {
    window.document.addEventListener("click", this.blurHandler);
  }

  componentWillUnmount() {
    window.document.removeEventListener("click", this.blurHandler);
  }

  container = React.createRef();

  @observable profileOpen = false;

  toggleProfile = (e) => {
    e.preventDefault();
    this.profileOpen = !this.profileOpen;
  };

  blurHandler = (e) => {
    if (!this.container.current.contains(e.target)) {
      this.profileOpen = false;
    }
  };

  render() {
    const { signOut, profile } = this.props.store.auth;
    const { displayName, avatarURL, joinDate } = profile;
    return (
      <li
        className={cl({
          "dropdown user user-menu": true,
          open: this.profileOpen,
        })}
        ref={this.container}
      >
        <a className="dropdown-toggle" data-toggle="dropdown" onClick={this.toggleProfile}>
          <img src={avatarURL} className="user-image" alt="User" />
          <span className="hidden-xs">{displayName}</span>
        </a>
        <ul className="dropdown-menu">
          <li className="user-header">
            <img src={avatarURL} className="img-circle" alt="User" />
            <p>
              {displayName}
              <small>Member since {dateformat(joinDate, "mmm ddS yyyy")}</small>
            </p>
          </li>
          <li className="user-footer">
            <div className="pull-left">
              <a className="btn btn-default btn-flat">Profile</a>
            </div>
            <div className="pull-right">
              <a className="btn btn-default btn-flat" onClick={signOut}>
                Sign out
              </a>
            </div>
          </li>
        </ul>
      </li>
    );
  }
}

export default HeaderProfile;
