import { lazy } from "react";
import { Loadable } from "utils/loadable";

import LoadingComponent from "../components/LoadingComponent";

const dashboardRoutes = [
  { redirect: true, exact: true, path: "/", to: "/dashboard", navbarName: "Redirect" },
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: "fa fa-dashboard",
    component: Loadable({
      Component: lazy(() => import("../views/Home")),
      Loading: LoadingComponent,
    }),
  },
  {
    path: "/links-mng",
    sidebarName: "Links Manager",
    navbarName: "Links Manager",
    icon: "fa fa-laptop",
    component: Loadable({
      Component: lazy(() => import("../views/LinksMng")),
      Loading: LoadingComponent,
    }),
  },
];

export default dashboardRoutes;
