import { types, getParent } from "mobx-state-tree";

const FileInfo = types
  .model("FileInfo", {
    ID: types.identifier,
    Title: types.string,
    Cover: types.string,
    Year: types.maybe(types.string),
    PageNumber: types.maybe(types.union(types.string, types.number)),
    Author: types.maybe(types.string),
    Categories: types.maybe(types.string),
    Tags: types.maybe(types.string),
    Description: types.maybe(types.string),
    Description2: types.maybe(types.string),
    Description3: types.maybe(types.string),
    Review: types.maybe(types.string),
    Review2: types.maybe(types.string),
    key: types.string,
    LinkDownload: types.string,
    FileSizeBytes: types.number,
    FileType: types.string,
    FileID: types.optional(types.string, ""),
    errorAt: types.optional(types.number, 0),
    errorMsg: types.optional(types.string, ""),
    meta: types.string,
  })
  .actions((self) => ({
    destroy() {
      getParent(self, 2).destroy(self);
    },
    update(book) {
      getParent(self, 2).update(self.key, book);
    },
  }));

export default FileInfo;
