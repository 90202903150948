import "./SkinCard.scss";
import cl from "classnames";
import { observer, inject } from "mobx-react";
import React from "react";

@inject("ui")
@observer
class SkinCard extends React.Component {
  changeSkin = () => {
    const { ui, skin } = this.props;
    ui.changeSkin(skin.className);
  };
  render() {
    const { name, palette, className } = this.props.skin;
    const isLightSkin = className.indexOf("light") > -1;
    return (
      <li
        className={cl({
          "skin-card": true,
          "skin-card-light": isLightSkin,
        })}
      >
        <a className="clearfix full-opacity-hover skin-preview" onClick={this.changeSkin}>
          <div>
            <span className="top-left" style={{ background: palette.topLeft }} />
            <span className="top-right" style={{ background: palette.topRight }} />
          </div>
          <div>
            <span className="bottom-left" style={{ background: palette.bottomLeft }} />
            <span className="bottom-right" style={{ background: palette.bottomRight }} />
          </div>
        </a>
        <p className="text-center no-margin">{name}</p>
      </li>
    );
  }
}
export default SkinCard;
