import "./Login.scss";
import LoadingIcon from "components/LoadingIcon";
import trim from "lodash/trim";
import { observer, inject } from "mobx-react";
import queryString from "query-string";
import React from "react";
import { Redirect, Link } from "react-router-dom";

import { STATUS } from "../../stores/Auth";

@inject("store")
@observer
class Login extends React.Component {
  emailRef = React.createRef();
  passwdRef = React.createRef();

  handleLogin = (e) => {
    e.preventDefault();
    const email = trim(this.emailRef.current.value);
    const passwd = trim(this.passwdRef.current.value);

    if (email.length > 0 && passwd.length > 0) {
      this.props.store.auth.signIn(email, passwd);
    }
  };

  render() {
    const { store, location } = this.props;
    const {
      auth: { status, profile, error },
    } = store;
    if (status === STATUS.READY && profile) {
      const queryParams = queryString.parse(location.search);
      const redirect = decodeURIComponent(queryParams.to) || "/dashboard";
      return <Redirect to={redirect} />;
    }
    return (
      <div className="login-page">
        <div className="login-box">
          <div className="login-logo">
            <a href="../../index2.html">
              <b>Admin</b>LTE
            </a>
          </div>
          <div className="login-box-body">
            {status === STATUS.READY ? (
              <React.Fragment>
                <p className="login-box-msg">Sign in to start your session</p>
                <form onSubmit={this.handleLogin}>
                  <div className="form-group has-feedback">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      autoComplete="email"
                      ref={this.emailRef}
                    />
                    <span className="glyphicon glyphicon-envelope form-control-feedback" />
                  </div>
                  <div className="form-group has-feedback">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      autoComplete="password"
                      ref={this.passwdRef}
                    />
                    <span className="glyphicon glyphicon-lock form-control-feedback" />
                  </div>
                  {error && <span className="text-danger">{error}</span>}
                  <div className="row">
                    <div className="col-xs-8">
                      <div className="checkbox">
                        <label>
                          <input type="checkbox" /> Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <button type="submit" className="btn btn-primary btn-block btn-flat">
                        Sign In
                      </button>
                    </div>
                  </div>
                </form>
                <Link to="/forgot-password">I forgot my password</Link> <br />
              </React.Fragment>
            ) : (
              <LoadingIcon text={status} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
