import { observer } from "mobx-react";
import React from "react";

@observer
class Box extends React.Component {
  render() {
    const { children, title, loading, type } = this.props;
    return (
      <div className={type ? `box box-${type}` : "box"}>
        {title && (
          <div className="box-header with-border">
            <h3 className="box-title">{title}</h3>
          </div>
        )}
        <div className="box-body">{children}</div>
        {loading && (
          <div className="overlay">
            <i className="fa fa-refresh fa-spin" />
          </div>
        )}
      </div>
    );
  }
}

export default Box;
