import cl from "classnames";
import { observer, inject } from "mobx-react";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Loadable } from "utils/loadable";

import ContentHeader from "../components/ContentHeader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingComponent from "../components/LoadingComponent";
import RightSidebar from "../components/RightSidebar";
import Sidebar from "../components/Sidebar";
import dashboardRoutes from "../routes/dashboard";

const ErrorPage = Loadable({
  Component: () => import("../views/ErrorPage"),
  Loading: LoadingComponent,
});

@inject("ui")
@observer
class Dashboard extends React.Component {
  render() {
    const { sidebarOpen, skin, layout } = this.props.ui;
    const { pathname } = this.props.location;
    const activeRoute = dashboardRoutes.find((route) => route.path === pathname);
    return (
      <div
        className={cl({
          "sidebar-mini": true,
          [layout]: true,
          [`skin-${skin}`]: true,
          "sidebar-open": sidebarOpen,
          "sidebar-collapse": !sidebarOpen,
        })}
      >
        <div className="wrapper">
          <Header />
          <Sidebar pathname={pathname} />
          <div className="content-wrapper">
            {activeRoute && <ContentHeader activeRoute={activeRoute} />}
            <section className="content">
              <Switch>
                {dashboardRoutes.map((prop, key) => {
                  if (prop.redirect) {
                    return (
                      <Redirect exact={!!prop.exact} from={prop.path} to={prop.to} key={key} />
                    );
                  }
                  return <Route path={prop.path} component={prop.component} key={key} />;
                })}
                <Route component={ErrorPage} />;
              </Switch>
            </section>
          </div>
          <Footer />
          <RightSidebar />
        </div>
      </div>
    );
  }
}

export default Dashboard;
