import { types } from "mobx-state-tree";

const UI = types
  .model("UI", {
    sidebarOpen: true,
    rightSidebarOpen: false,
    skin: "blue",
    layout: types.optional(types.string, "", "fixed", "layout-boxed"),
  })
  .actions((self) => ({
    toggleSidebar() {
      self.sidebarOpen = !self.sidebarOpen;
    },
    toggleRightSidebar() {
      self.rightSidebarOpen = !self.rightSidebarOpen;
    },
    changeSkin(skin) {
      self.skin = skin;
    },
    changeLayout(layout) {
      self.layout = layout;
    },
  }));

export default UI;
