import "./Sidebar.scss";
import defaultAvatar from "assets/img/avatar5.png";
import cl from "classnames";
import { observer, inject } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import dashboardRoutes from "routes/dashboard";

@inject("store")
@observer
class Sidebar extends React.Component {
  render() {
    const { pathname, store } = this.props;
    const { uid, photoURL, email, displayName } = store.auth.profile;
    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <div className="user-panel">
            <div className="pull-left image">
              <img src={photoURL || defaultAvatar} className="img-circle" alt="Avatar" />
            </div>
            <div className="pull-left info">
              <p>{displayName || email || uid || "John Doe"}</p>
              <a>
                <i className="fa fa-circle text-success" /> Online
              </a>
            </div>
          </div>
          <form action="#" method="get" className="sidebar-form">
            <div className="input-group">
              <input type="text" name="q" className="form-control" placeholder="Search..." />
              <span className="input-group-btn">
                <button type="submit" name="search" id="search-btn" className="btn btn-flat">
                  <i className="fa fa-search" />
                </button>
              </span>
            </div>
          </form>
          <ul className="sidebar-menu" data-widget="tree">
            <li className="header">MAIN NAVIGATION</li>
            {dashboardRoutes.map((route, key) => {
              if (route.redirect) {
                return null;
              }
              const { path, icon, sidebarName } = route;
              return (
                <li
                  key={key}
                  className={cl({
                    active: pathname === path,
                  })}
                >
                  <Link to={path}>
                    <i className={icon} />
                    <span>{sidebarName}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      </aside>
    );
  }
}
export default Sidebar;
