import { types, getEnv } from "mobx-state-tree";

import FileInfo from "./FileInfo";

export default types
  .model("LinksMng", {
    files: types.optional(types.array(FileInfo), []),
    loading: false,
  })
  .volatile((self) => ({
    database: null,
  }))
  .actions((self) => ({
    afterCreate() {
      const { database } = getEnv(self).firebase;
      self.database = database;
    },
    handleBookSnapshot(snapshot) {
      self.loading = false;
      const books = [];
      const booksMap = snapshot.val();
      for (const key in booksMap) {
        if (booksMap.hasOwnProperty(key)) {
          const originBook = booksMap[key];

          const book = {
            ...originBook,
            Title: originBook.Title || originBook.title,
            ID: originBook.ID || originBook.PostID,
            key,
          };
          if (!(book.ID && book.Title && book.LinkDownload && book.FileSizeBytes)) {
            continue;
          }
          book.meta = JSON.stringify(book);
          books.push(book);
        }
      }
      self.files.replace(books);
    },
    updateFileList(files) {
      self.files.replace(files);
    },
    syncFileListOn() {
      self.loading = true;
      const booksRef = self.database.ref("/books");
      booksRef.on("value", self.handleBookSnapshot, console.error);
    },
    syncFileListOff() {
      self.database.ref("/books").off("value", self.handleBookSnapshot);
    },
    requestImportFiles(newFiles) {
      const counter = {
        add: 0,
        update: 0,
      };
      newFiles.forEach((newFile) => {
        const { key, Key, ...info } = newFile;
        const ref = Key ?? key;
        if (ref) {
          self.database.ref(`/books/${ref}`).set(info);
          counter.update++;
        } else {
          self.database.ref("/books").push(info);
          counter.add++;
        }
      });
      return counter;
    },
    update(key, book) {
      const fileRef = self.database.ref(`/books/${key}`);
      fileRef.update(book);
    },
    destroy(file) {
      const fileRef = self.database.ref(`/books/${file.key}`);
      fileRef.remove();
    },
    truncate() {
      self.database.ref("/books").remove();
    },
  }));
