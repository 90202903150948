import { observer, inject } from "mobx-react";
import React from "react";

import HeaderProfile from "./HeaderProfile";

@inject("ui")
@observer
class Header extends React.Component {
  pushMenu = (e) => {
    e.preventDefault();
    const { ui } = this.props;
    ui.toggleSidebar();
  };

  toggleSettingBar = (e) => {
    e.preventDefault();
    const { ui } = this.props;
    ui.toggleRightSidebar();
  };

  render() {
    return (
      <header className="main-header">
        <a href="index2.html" className="logo">
          <span className="logo-mini">
            <b>A</b>LT
          </span>
          <span className="logo-lg">
            <b>Admin</b>LTE
          </span>
        </a>
        <nav className="navbar navbar-static-top">
          <a className="sidebar-toggle" role="button" onClick={this.pushMenu}>
            <span className="sr-only">Toggle navigation</span>
          </a>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <HeaderProfile toggleProfile={this.toggleProfile} />
              <li>
                <a data-toggle="control-sidebar" onClick={this.toggleSettingBar}>
                  <i className="fa fa-gears" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
export default Header;
