import defaultAvatar from "assets/img/avatar5.png";
import { types, getRoot } from "mobx-state-tree";

const Profile = types
  .model("Profile", {
    uid: types.identifier,
    photoURL: types.maybeNull(types.string),
    phoneNumber: types.maybeNull(types.number),
    email: types.string,
    name: types.maybeNull(types.string),
    joinDate: types.Date,
  })
  .views((self) => ({
    get displayName() {
      return self.name || self.email;
    },
    get avatarURL() {
      return self.photoURL || defaultAvatar;
    },
  }))
  .actions((self) => ({
    signOut() {
      getRoot(self).signOut();
    },
  }));

export default Profile;
